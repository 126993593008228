$(document).ready(function ($) {

    let owl = $('#search-carousel');
    owl.owlCarousel({
        margin: 30,
        loop: true,
        nav: false,
        dots: false,
        autoplay: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 3
            }
        }
    });

    $('#pagination-search .next').click(() => {
        owl.trigger('next.owl.carousel');
    })

    $('#pagination-search .prev').click(() => {
        owl.trigger('prev.owl.carousel');
    })

    let owl2 = $('#ultimos-carousel');
    owl2.owlCarousel({
        margin: 30,
        loop: true,
        nav: false,
        dots: false,
        autoplay: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 3
            }
        }
    });

    $('#ultimos-pagination .next').click(() => {
        owl2.trigger('next.owl.carousel');
    });

    $('#ultimos-pagination .prev').click(() => {
        owl2.trigger('prev.owl.carousel');
    });

    let owl4 = $('#detalhes-carousel');
    owl4.owlCarousel({
        margin: 30,
        loop: true,
        nav: true,
        dots: false,
        autoplay: false,
        responsive: {
            0: {
                items: 1
            }
        }
    });
});

