import 'es6-promise/auto'
import './bootstrap'
import 'bootstrap-slider'
import './custom/house.timer'
import './pages/home'
import 'owl.carousel'
import './custom/owl-carousel'
import 'bootstrap/dist/js/bootstrap'
import numeral from 'numeral';

numeral.register('locale', 'pt-BR', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    currency: {
        symbol: 'R$'
    }
});
numeral.locale('pt-BR');
