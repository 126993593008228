import moment from "moment";

$(document).ready(function ($) {

    const getTwoParts = number => {

        if (number < 10) {
            return [0, number];
        }

        number = number + "";
        return [number.substr(0, number.length - 1), number.substr(-1, 1)];
    }

    const timers = [];
    $('.timer').each((index, item) => {

        const day = $(item).find('ul li').eq(0).find('span').eq(0).html() + $(item).find('ul li').eq(0).find('span').eq(1).html();
        const hour = $(item).find('ul li').eq(1).find('span').eq(0).html() + $(item).find('ul li').eq(1).find('span').eq(1).html();
        const minute = $(item).find('ul li').eq(2).find('span').eq(0).html() + $(item).find('ul li').eq(2).find('span').eq(1).html();
        const second = $(item).find('ul li').eq(3).find('span').eq(0).html() + $(item).find('ul li').eq(3).find('span').eq(1).html();

        const momentDate = moment(new Date());
        momentDate.add('days', day);
        momentDate.add('hours', hour);
        momentDate.add('minutes', minute);
        momentDate.add('seconds', second);

        const countDownDate = momentDate.valueOf();
        timers[index] = setInterval(function () {

            const now = new Date().getTime();
            const timeleft = countDownDate - now;

            const days = getTwoParts(Math.floor(timeleft / (1000 * 60 * 60 * 24)));
            const hours = getTwoParts(Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
            const minutes = getTwoParts(Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60)));
            const seconds = getTwoParts(Math.floor((timeleft % (1000 * 60)) / 1000));

            $(item).find('ul li').eq(0).find('span').eq(0).html(days[0]);
            $(item).find('ul li').eq(0).find('span').eq(1).html(days[1]);

            $(item).find('ul li').eq(1).find('span').eq(0).html(hours[0]);
            $(item).find('ul li').eq(1).find('span').eq(1).html(hours[1]);

            $(item).find('ul li').eq(2).find('span').eq(0).html(minutes[0]);
            $(item).find('ul li').eq(2).find('span').eq(1).html(minutes[1]);

            $(item).find('ul li').eq(3).find('span').eq(0).html(seconds[0]);
            $(item).find('ul li').eq(3).find('span').eq(1).html(seconds[1]);

            if (timeleft < 0) {
                clearInterval(timers[index]);
            }

        }, 1000);

    });


});
