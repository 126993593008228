const _ = require('lodash');

$(document).ready(function ($) {

    $("#home-slider").on("slide", _.debounce(function (slideEvt) {
        const valor = slideEvt.value;

        const valorMinimo = valor[0];
        const valorMaximo = valor[1];

        $('#min-hidden').val(valorMinimo);
        $('#max-hidden').val(valorMaximo);

        $("#home-slider-description").html('Faixa de preço R$ ' + (valorMinimo / 1000) + ' ~ R$ ' + (valorMaximo / 1000) + ' mil:');

        $("#search-go").fadeOut(400, function () {
            $(this).html("Encontrar os resultados! <i class='fas fa-chevron-circle-right icon'></i>").fadeIn(400);
        });

    }, 150));
});
